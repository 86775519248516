.dialog-container {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(64, 65, 69, 0.38);
  backdrop-filter: blur(7px);
  width: 100%;
  min-height: 100vh;
  display: none;
  justify-content: center;
  align-items: center;

  &.visible {
    display: flex;
  }
}
