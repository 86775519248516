.consult-select {
  width: 100%;
  height: 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  color: #454f5d;
  cursor: pointer;
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 15px;
  position: relative;
  transition: all 0.2s;

  &.small {
    flex-direction: column;
    align-items: flex-start !important;
    text-align: left;
    padding: 15px 16px;
    height: auto !important;
  }

  &:hover {
    box-shadow: 0px 4px 20px rgba(53, 49, 56, 0.1);
  }

  @media (max-width: 1200px) {
    padding: 15px 20px;
    height: auto;
  }
}

.discount-badge-wrapper {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 80px;
  height: 80px;
  transform: translate(50px, -22px);

  @media (max-width: 1200px) {
    width: 70px;
    height: 70px;
    transform: translate(20px, -36px);
  }
}
