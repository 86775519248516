#section4 .card {
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 20px 20px 0 0;
  padding: 47px 40px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  box-shadow: 0px 3.85714px 21.2143px rgba(0, 0, 0, 0.1);
  @media (max-width: 1200px) {
    width: 280px;
    height: 400px;
    border-radius: 20px;
    padding: 32px 20px;
  }
}
#section4 .card .card-title {
  color: #454f5d;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: -0.88px;
  font-weight: 700;
  margin-bottom: 14px;

  @media (max-width: 1200px) {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 32px;
  }
}

#section4 .card .card-sub {
  color: #838a9a;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 55px;
  letter-spacing: -0.5px;

  @media (max-width: 1200px) {
    margin-bottom: 28px;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.5px;
  }
}

#section4 #card5 .card-sub {
  margin-bottom: 32px;
}

#section4 .card#card3 .card-sub {
  margin-bottom: 40px;
  @media (max-width: 1200px) {
    margin-bottom: 26px;
  }
}

#section4 .card .info-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 29px;
  @media (max-width: 1200px) {
    gap: 24px;
  }
}

#section4 .card .info-wrapper .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #29292e;
  font-weight: 700;
  font-size: 20px;

  &.blue {
    color: #547aff;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    font-size: 16px;
  }
}

#section4 .card .info-wrapper .info .bar {
  width: 70%;
  height: 46px;
  background: #29292e;
  color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 20px;

  &.blue {
    background: #547aff;
  }

  @media (max-width: 1200px) {
    border-radius: 6px;
    padding: 9px 10px;
    font-size: 15px;
    font-weight: 500;
  }
}

#section4 .card .info-wrapper .info:nth-of-type(1) .bar {
  // width: 70%;
  width: 343px;

  @media (max-width: 1200px) {
    font-size: 15px;
    width: 213px;
    height: 35px;
  }
}

#section4 .card .info-wrapper .info:nth-of-type(2) .bar {
  // width: 50%;
  width: 242px;

  @media (max-width: 1200px) {
    width: 141px;
    height: 35px;
  }
}

#section4 .card .card-content {
  margin: 0 auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (max-width: 1200px) {
    width: 100%;
    // flex-grow: 1;
    justify-content: space-between;
    gap: 8px;
  }
}

#section4 #card4 .card-sub {
  margin-bottom: 20px;
  @media (max-width: 1200px) {
    margin-bottom: 22px;
  }
}

#section4 #card{
  &4{
    .card-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      gap: 0;
      padding-bottom: 30px;

      @media (max-width: 1200px) {
        padding-bottom: 0px;
      }
    }
  }
  &5{
    .card-content {
      width: 100%;
      flex-grow: 1;
      // gap: 0;
      padding-bottom: 30px;
      position: relative;

      @media (max-width: 1200px) {
        padding-bottom: 0px;
      }
      #card5-image{
        margin: 0 auto;
        @media (max-width: 1200px) {
          width: 100%;

        }
      }
      .mostBig-marker{
        position: absolute;
        width: 128px;
        height: 164px;
        top: 13px;
        left: 209px;
      }
      .secondBig-marker{
        position: absolute;
        top: 6px;
        left: 56px;
        width: 86px;
        height: 110px;
      }
      .thirdBig-marker{
        position: absolute;
        top: -5px;
        right: 100px;
        width: 57px;
        height: 73px;
      }
    }
  }
}

#section4 .card .card-content li {
  width: 100%;
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
  background: #F67E58;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.5px;

  @media (max-width: 1200px) {
    font-size: 16px;
    line-height: 48px;
    height: 48px;
  }
}

#section4 .card .card-content label {
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #abb2c2;
  margin-top: 10px;

  @media (max-width: 1200px) {
    font-size: 14px;
    line-height: 24px;
  }
}

#section4 .card .card-content .card-image {
  margin: 0 auto;

  @media (max-width: 1200px) {
    width: 100%;
  }
}

#section4 .mode-2 .card-content {
  margin: 0;
}

