.terms-container {
  padding-top: calc(80px + 90px);
  text-align: left;
  width: 1080px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    width: 100%;
    padding-top: calc(56px + 40px);
    padding-left: 20px;
    padding-right: 20px;
  }
}

.terms-container .section {
  margin-bottom: 60px;

  @media (max-width: 1200px) {
    margin-bottom: 40px;
  }
}

.terms-container h1 {
  font-size: 30px;
  line-height: 37px;
  font-weight: 700;
  margin-bottom: 40px;

  @media (max-width: 1200px) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 16px;
  }
}

.terms-container h2 {
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 16px;
  font-weight: 400;

  @media (max-width: 1200px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.terms-container p {
  font-size: 16px;
  line-height: 28px;

  @media (max-width: 1200px) {
    font-size: 14px;
    line-height: 28px;
  }
}

.terms-container p.indent {
  margin-left: 20px;

  @media (max-width: 1200px) {
    margin-left: 0;
  }
}
