#section4 .section-inner {
  padding-bottom: 80px;
  transition: all 0.1s;

  &.mode-0 {
    background: #c5d0f9;
  }
  &.mode-1 {
    background: #dff5f0;
  }
  &.mode-2 {
    background: #ffdfd6;
  }
  &.mode-3 {
    background: #f9e7fa;
  }
  &.mode-4 {
    background: #EEF8FD;
  }

  @media (max-width: 1200px) {
    width: 100%;
    max-width: none;
  }
}

#section4 .section-content {
  max-width: 298px;
  margin: 0 auto;
}

#section4 .section-header {
  text-align: center;
}

.slick-dots li {
  margin: 0 1px !important;
}

.slick-dots li button::before {
  color: rgba(41, 41, 46, 0.2);
  font-size: 24px;
}

.slick-dots li:nth-of-type(1).slick-active button::before {
  color: #547aff;
}

.slick-dots li:nth-of-type(2).slick-active button::before {
  color: #4fe3c1;
}

.slick-dots li:nth-of-type(3).slick-active button::before {
  color: #fc764c;
}

.swiper {
  width: 100%;
  height: auto;
  overflow: visible;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

#section4 .swiper-pagination {
  bottom: -44px !important;
}

#section4 .swiper-pagination-bullet-active {
  background: #547aff;
}

#section4 .swiper-pagination-bullet-active:nth-of-type(2) {
  background: #4fe3c1;
}

#section4 .swiper-pagination-bullet-active:nth-of-type(3) {
  background: #fc764c;
}

#section4 .swiper-pagination-bullet-active:nth-of-type(4) {
  background: #ee7eee;
}

#section4 .swiper-pagination-bullet-active:nth-of-type(5) {
  background: #2DBCFF;
}
