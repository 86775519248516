.consult-select#type-b .left {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 20px;
  line-height: 30px;

  @media (max-width: 1200px) {
    gap: 5px;
    font-size: 18px;
  }
}

.consult-select#type-b .left .best {
  padding: 0 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 27px;
  color: #fff;
  background: #8e51e0;
  border-radius: 50px;
  height: 27px;

  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 21px;
    letter-spacing: -0.62px;
    height: auto;
    padding: 0 8px;
  }
}

.consult-select#type-b .right {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;

  @media (max-width: 1200px) {
    gap: 5px;
    font-size: 12px;
  }
}

.consult-select#type-b .right label {
  font-size: 17px;
  line-height: 24px;
  font-weight: 400;
  color: #b0b7c7;
  text-decoration: line-through;

  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 24px;
  }
}

.consult-select#type-b .right strong {
  font-size: 18px;

  @media (max-width: 1200px) {
    font-size: 16px;
  }
}

.og-price-wrapper {
  color: #b0b7c7;
}

.og-price {
  color: #b0b7c7;
  text-decoration-line: line-through;
  font-weight: 500;
}

.vertical-divider {
  color: #b0b7c7;
  text-align: right;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Spoqa Han Sans Neo";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-left: 4px;
  margin-right: 4px;

  @media (max-width: 1200px) {
    font-size: 12px;
  }
}

.consult-select {
  height: 80px;
}
