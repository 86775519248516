footer {
  width: 100%;
  height: 300px;
  background: #f5f5f7;
}

footer .footer-inner {
  /* display: flex; */
  width: 1080px;
  padding-top: 50px;
  padding-bottom: 150px;
  margin: 0 auto;
  color: #acb5c1;
  font-size: 16px;
  text-align: left;
}

/* footer .footer-inner div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
} */

/* footer .footer-inner div:nth-of-type(1) {
  flex: 2.2;
}

footer .footer-inner div:nth-of-type(2) {
  flex: 1;
}

footer .footer-inner div:nth-of-type(3) {
  flex: 1;
} */

footer .footer-content-wrapper {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.8px;
}

footer .footer-content-wrapper .text-content {
  margin-top: 25px;
}

footer .footer-content-wrapper .text-content-group > * {
  display: inline-block;
  font-style: normal;
}

footer .footer-content-wrapper .text-content-group > *::after {
  content: "|";
  margin: 0 15px;
}

footer .footer-content-wrapper .text-content-group > *:last-child::after {
  content: "";
}

footer .footer-content-wrapper .text-content .biz-content {
  line-height: 26px;
  font-size: 14px;
  letter-spacing: -0.8px;
}

footer .footer-logo-wrapper {
  /* flex-grow: 1; */
  margin-right: 362px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

footer .footer-logo {
  width: 188px;
}

footer .footer-payple-logo {
  width: 188px;
  margin-left: auto;
}

@media (max-width: 1200px) {
  footer {
    height: initial;
  }

  footer .footer-inner {
    font-size: 14px;
    padding: 34px 20px;
    padding-bottom: 120px;
    /* width: 100%; */
    width: 320px;
    margin: 0 auto;
    flex-direction: column;
  }

  footer .footer-logo-wrapper {
    margin-right: 0;
  }

  footer .footer-content-wrapper {
    flex-direction: column;
    margin-top: 16px;
  }

  footer .footer-content-wrapper .text-content {
    line-height: 26px;
    font-size: 14px;
  }

  footer .footer-content-wrapper .text-content:last-child {
    margin-left: 0;
    margin-top: 16px;
  }
  footer .footer-content-wrapper .text-content:last-child br {
    display: none;
  }

  footer .footer-content-wrapper .text-content-group > *::after {
    content: "";
    margin: 0;
  }

  footer .footer-content-wrapper .text-content-group > * {
    display: block;
  }

  footer .footer-logo {
    width: 160px;
  }

  /* footer .footer-inner div:not(:first-child) {
    margin-top: 20px;
  } */
}
