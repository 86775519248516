.mobile-header {
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid #eff0f2;
  background: #fff;
  transition: all ease 0.2s;
  height: 56px;
  overflow: hidden;
  top: 0;
  z-index: 10;
  position: fixed;
  transition-delay: 0.2s;

  &.expand_ {
    height: 468px;
  }
}

.mobile-header.header-collapse_ {
  transform: translateY(-56px);
}

.mobile-header .gnb {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.mobile-header .gnb .logo svg {
  display: block;
}

.mobile-header .gnb .menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.mobile-header .gnb .menu-toggle .toggle-icon {
  display: inline-block;
  position: relative;
  width: 20px;
  // height: 11px;
  height: 14px;
}

.mobile-header .gnb .menu-toggle span {
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  width: 20px;
  height: 3px;
  background: #aeb2b8;
  border-radius: 2px;
  transition: all 0.4s;

  &:nth-of-type(2) {
    top: 7px;
  }
}

.mobile-header.expand_ .menu-toggle span:nth-of-type(1) {
  transform: rotate(-45deg);
}

.mobile-header.expand_ .menu-toggle span:nth-of-type(2) {
  transform: translateY(-7px) rotate(45deg);
}

.mobile-header .mobile-nav {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 20px;
}

.mobile-header .mobile-nav ul {
  border-bottom: 1px solid #eff0f2;
  padding: 8px 0;

  &:first-child {
    color: #787b83;
    padding-bottom: 14px;
  }

  &:last-child {
    border: none;
  }
}

.mobile-header .mobile-nav li {
  width: 100%;
  font-size: 18px;
  line-height: 50px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    height: 20px;
    width: auto;
  }
}

.mobile-header-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  z-index: 9;

  &.visible {
    display: block;
  }
}
