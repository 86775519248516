.consult-dialog-card {
  position: fixed;
  width: 426px;
  padding: 40px 25px;
  border-radius: 24px;
  z-index: 999;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  background: #eff2ff;
}

.consult-dialog-card .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.consult-dialog-card .card-main {
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.consult-dialog-card .card-main .card-title {
  font-size: 20px;
  color: #5b7cff;
}

.consult-dialog-card .card-main .card-desc {
  width: 70%;
  font-size: 20px;
  color: #7f8bba;
}
