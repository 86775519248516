div.review-card {
  width: 414px;
  height: 272px;

  border-radius: 30px;
  background: #F5F5F7;

  padding: 32px 28px;

  display: flex;
  justify-content: space-between;
  gap: 8px;

  & > .left-area {
    width: 35px;
    height: 35px;
    background: url("../../static/profile-circle-grey.svg") no-repeat center;
  }

  & > .right-area {
    width: 315px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    & > .right-upper {
      display: flex;
      justify-content: space-between;

      height: 24px;

      & > .review-value {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 4px;

        & > div {
          width: 94px;
          height: 16px;
          background: url("../../static/stars.svg") no-repeat center;
        }

        & > p {
          color: #333437;
          font-size: 16px;
          font-weight: 700;
          line-height: 19.867px;
          letter-spacing: -0.467px;
        }
      }

      & >  .review-status {
        height: 24px;
        border-radius: 1px;
        border: 1px solid #5090F0;
        padding: 2px 4px;
        text-align: center;

        color: #5090F0;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.467px;
      }
    }

    & > .right-mid {
      height: 48px;
      text-align: left;

      color: #82858D;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.467px;
    }

    & >  .right-lower {
      text-align: left;
      word-break: break-all;

      color: #454F5D;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: -0.5px;
    }
  }
}

@media (max-width: 1200px) {
  div.review-card {
    width: 281.47px;
    height: 184.93px;
    border-radius: 20px;
    padding: 22px 19px;

    gap: 5.44px;

    & > .left-area {
      width: 23.8px;
      height: 23.8px;
      background:url("../../static/profile-circle-grey-mobile.svg") no-repeat center;
    }

    & >  .right-area {
      width: 214px;

      gap: 5.44px;

      & >  .right-upper {
        height: 16.3px;

        & > .review-value {
          gap: 2.72px;
          
          & > div {
            width: 63.909px;
            height: 10.878px;
            background: url("../../static/stars-mobile.svg") no-repeat center;
          }

          & > p {
            color: #333437;
            font-size: 10.878px;
            font-weight: 700;
            line-height: 13.507px;
            letter-spacing: -0.317px;
          }
        }

        & >  .review-status {
          height: 16.72px;
          padding: 1px 3px;
          text-align: center;
  
          color: #5090F0;
          font-size: 10.878px;
          font-weight: 500;
          line-height: 13.598px;
          letter-spacing: -0.317px;
        }
      }

      & > .right-mid {
        height: 34px;
        font-size: 10.878px;
        font-weight: 500;
        line-height: 16.317px;
        letter-spacing: -0.317px;
      }

      & > .right-lower {
        font-size: 13.598px;
        font-weight: 500;
        line-height: 20.397px;
        letter-spacing: -0.34px;
      }
    }
  }
}