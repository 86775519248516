.float-button-container {
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 48.44%,
    #ffffff 100%
  );
  transition: all ease 0.2s;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.float-button-wrapper {
  width: 100%;
  max-width: 1200px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;

  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center;
    height: 120px;
  }
}

.float-button-wrapper p {
  color: #686d79;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.5px;

  @media (max-width: 1200px) {
    display: none;
  }
}

.float-button {
  background: linear-gradient(90deg, #5e6eff 100%, #4bcdff 0%);
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  width: 250px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50px;

  &:hover {
    background: linear-gradient(90deg, #5e6eff 0%, #4bcdff 105.41%);
    box-shadow: 8px 8px 117px 0.5px #e0dfe5;
  }

  @media (max-width: 1200px) {
    width: 213px;
    height: 56px;
    font-size: 18px;
    right: 0;
    left: 0;
    bottom: 5px;
    margin: 0 auto;
  }
}
