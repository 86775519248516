.consult-dialog {
  display: none;
  position: fixed;

  &.visible {
    display: flex;
  }
}

.consult-dialog .overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(64, 65, 69, 0.38);
  backdrop-filter: blur(5px);
}
