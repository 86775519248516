#section2_3 {
  padding-top: 141px;
  padding-bottom: 136px;

  background: #FFF;

  & > .sec2_3-header {

    margin-bottom: 75px;

    & > h5 {
      color: #454F5D;
      text-align: center;
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -0.875px;

      & > span {
        color: #5D73FF;
      }

      margin-bottom: 16px;
    }

    & > p {
      color: #838A9A;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.5px;
    }
  }

  & > .sec2_3-main {
    width: 100%;
    height: 272px;
    margin-bottom: 75px;
  
    overflow: hidden;

    & > .swiper {
      & > .swiper-wrapper {
        transition-timing-function: linear;

        & .swiper-slide {
          width: 414px !important;
        } 
      }
    }
  }

  & >  .sec2_3-tail {
    display: flex;
    justify-content: center;
    align-items: center;

    & a {

      & > button {
        width: 246px;
        height: 84px;
        border-radius: 50px;
        background: var(--main, #5D73FF);
        box-shadow: 8px 8px 117px 0.5px #E0DFE5;

        color: #F9FAFB;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
      }
    }

  }
}

@media (max-width: 1200px) {
  #section2_3 {
    padding-top: 56px;
    padding-bottom: 62.07px;

    & > .sec2_3-header {
      margin-bottom: 49px;

      & > h5 {
        color: #454F5D;
        font-size: 28px;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: -0.8px;

        margin-bottom: 12px;
      }

      & > p {
        font-size: 15px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: -0.5px;
      }
    }
    
    & > .sec2_3-main-mobile {
      height: 391px;
      margin-bottom: 0px;
      
      display: flex;
      flex-direction: column;
      gap: 21.07px;
      
      overflow: hidden;

      & > .swiper {
        & > .swiper-wrapper {
          transition-timing-function: linear;
          
          & .swiper-slide {
            width: 281.47px !important;
          } 
        }
      }
    }
  }
}