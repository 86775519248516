#section5 .section-header {
  text-align: center;
  margin-bottom: 65px;

  @media (max-width: 1200px) {
    margin-bottom: 32px;
  }
}

#section5 .card-list {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    row-gap: 33px;
  }
}

// #section5 .card {
//   @media (max-width: 1200px) {
//     &:nth-of-type(1),
//     &:nth-of-type(2) {
//       margin-bottom: 20px;
//     }
//   }
// }

#section5 .card .card-image {
  width: 255px;
  height: 154px;
  border-radius: 20px;
  background-position: center center;
  background-size: cover;

  @media (max-width: 1200px) {
    width: auto;
    height: 105px;
    border-radius: 15px;
  }
}

#section5 .card .card-image#image_01 {
  background-image: url("../../static/main5_01.png");
}

#section5 .card .card-image#image_02 {
  background-image: url("../../static/main5_02.png");
}

#section5 .card .card-image#image_03 {
  background-image: url("../../static/main5_03.png");
}

#section5 .card .card-image#image_04 {
  background-image: url("../../static/main5_04.png");
}

#section5 .section-inner {
  padding-top: 70px;
  padding-bottom: 167px;
  @media (max-width: 1200px) {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

#section5 .section-inner .sub {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.48px;
  font-weight: 700;
  color: #454f5d;
  margin-top: 27px;

  @media (max-width: 1200px) {
    font-size: 15px;
    line-height: 20px;
    margin-top: 16px;
  }
}

#section5 .section-inner .label {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.48px;
  font-weight: 400;
  color: #b1b8c1;
  margin-top: 10px;

  @media (max-width: 1200px) {
    font-size: 14px;
    line-height: 20px;
    margin-top: 2px;
  }
}
